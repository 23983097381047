// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-denied {
  width: 60%;
  margin: 5rem auto;
  text-align: center;
}

.access-denied__heading {
  font-size: 3rem;
}

.access-denied__paragraph {
  font-size: 1.5rem;
}

.logout-box {
  max-width: 600px;
  margin: 2rem auto;
}`, "",{"version":3,"sources":["webpack://./src/app/access-denied/access-denied.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".access-denied {\n    width: 60%;\n    margin: 5rem auto;\n    text-align: center;\n}\n\n.access-denied__heading {\n    font-size: 3rem;\n}\n\n.access-denied__paragraph {\n    font-size: 1.5rem;\n}\n\n.logout-box {\n    max-width: 600px;\n    margin: 2rem auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
