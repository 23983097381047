import { Injectable } from "@angular/core";

import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    MatomoStatsUrl,
} from "@logex/framework/lg-application";

import { Configuration } from "src/app/shared/entities";

export interface IMatomo {
    siteId: number;
    organizationDimensionId: number;
    organizationNameDimensionId: number;
    tenantDimensionId: number;
    positionDimensionId: number;
    registryDimensionId: number;
    pageDimensionId: number;
}

@Injectable({ providedIn: "root" })
export class MatomoConfiguration implements IMatomoConfiguration {
    statUrl: string;
    siteId!: number;
    commonDimensionIds: IMatomoCommonDimensionIds;
    customDimensions = (): IMatomoDimension[] => this._getCustomDimensions();
    autoInit: boolean;
    autoTrack: boolean;
    configuration: Configuration | undefined;

    private _organizationId: number | undefined;
    private _organizationName: string | undefined;
    private _tenant: string | undefined;
    private _userPosition: string | undefined;

    constructor() {
        this.statUrl = MatomoStatsUrl.MRDM;
        this.commonDimensionIds = {};
        this.autoInit = true;
        this.autoTrack = true;
    }

    configure(config: Configuration): void {
        if (this.configuration) throw new Error("Configuration was already set");
        this.configuration = config;
        this.siteId = config.matomo?.siteId;
    }

    doNotDoMatTracking(): boolean {
        return window.location.hostname === "localhost";
    }

    setOrganizationId(organizationId: number): void {
        this._organizationId = organizationId;
    }

    setOrganizationName(organizationName: string): void {
        this._organizationName = organizationName;
    }

    setTenant(tenant: string): void {
        this._tenant = tenant;
    }

    setUserPosition(position: string | undefined): void {
        this._userPosition = position;
    }

    _getCustomDimensions(): IMatomoDimension[] {
        const result: IMatomoDimension[] = [];

        if (this._organizationId) {
            result.push({
                id: this.configuration!.matomo.organizationDimensionId,
                value: this._organizationId,
            });
        }

        if (this._organizationName) {
            result.push({
                id: this.configuration!.matomo.organizationNameDimensionId,
                value: this._organizationName,
            });
        }

        if (this._tenant) {
            result.push({
                id: this.configuration!.matomo.tenantDimensionId,
                value: this._tenant,
            });
        }

        if (this._userPosition) {
            result.push({
                id: this.configuration!.matomo.positionDimensionId,
                value: this._userPosition,
            });
        }
        return result;
    }
}
