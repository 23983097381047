import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { LineChartExtremes } from "@logex/framework/lg-charts";

import { Measurement, MeasurementVariableData } from "src/app/shared/entities";
import { round } from "src/app/shared/utils/math";

import { Format } from "../format";

interface ChartDataItem {
    label: string;
    practitioner: Measurement;
    benchmarkNl: Measurement;
    benchmarkGgdRegion: Measurement;
}

type ChartDataItems = ChartDataItem[];

@Component({
    selector: "app-trends-chart-view",
    templateUrl: "./chart-view.component.html",
    styleUrls: ["./chart-view.component.scss"],
})
export class ChapterTrendsChartViewComponent implements OnChanges {
    @Input() exporting = false;
    @Input() format: Format = "";
    @Input() data: MeasurementVariableData | null = null;

    public _chartDataItems: ChartDataItems = [];
    public _yAxisLabel = "";

    public _getColumnName = (item: ChartDataItem): string => {
        return item.label;
    };

    public _groupNames: string[] = [];
    public _groupColors: string[] = [];

    _setYAxisRange = (): LineChartExtremes => {
        let maxYAxisValue = this._maxValue;
        if (this._maxValue <= 1) {
            maxYAxisValue = 1;
        } else if (this._maxValue <= 10) {
            maxYAxisValue = 10;
        } else if (this._maxValue <= 100) {
            maxYAxisValue = 100;
        }
        return { min: 0, max: maxYAxisValue };
    };

    _decimalsToShow: 2 | 1 | 0 = 0;

    private _maxValue = 0;

    public _getGroupValues = (item: ChartDataItem): number[] => {
        if (!this.format) {
            return [0, 0, 0];
        }

        return [item.practitioner, item.benchmarkNl, item.benchmarkGgdRegion]
            .filter(group => group !== null)
            .map(group => group[this.format as Exclude<Format, "">] ?? null)
            .filter(value => value !== null)
            .map(value => (Number.isFinite(value) ? round(value, 2) : value));
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this._fillData(this.data);
            this._initGroups();
            this._calculateShownDecimals();
        }
    }

    private _initGroups(): void {
        this._groupNames = [];
        this._groupColors = [];
        this._setupGroupIfMeasurementExists("practitioner", "GLI-behandelaar", "#00A0AA");
        this._setupGroupIfMeasurementExists("benchmarkGgdRegion", "Benchmark GGD", "grey");
        this._setupGroupIfMeasurementExists("benchmarkNl", "Benchmark NL", "#E5E5E5");
    }

    private _setupGroupIfMeasurementExists(
        property: keyof ChartDataItem,
        groupName: string,
        groupColor: string,
    ): void {
        if (this._chartDataItems.find(item => item[property] !== null)) {
            this._groupNames.push(groupName);
            this._groupColors.push(groupColor);
        }
    }

    private _calculateShownDecimals(): void {
        const allMeasurements: Measurement[] = this._concatAllValidMeasurements();
        let formatKey: Format = this.format;
        if (!formatKey) formatKey = "average";
        this._maxValue = this._findMaxMeasurementValue(allMeasurements, formatKey);
        if (this._maxValue <= 1) {
            this._decimalsToShow = 2;
        } else if (this._maxValue <= 10) {
            this._decimalsToShow = 1;
        } else {
            this._decimalsToShow = 0;
        }
    }

    private _findMaxMeasurementValue(
        measurements: Measurement[],
        format: Exclude<Format, "">,
    ): number {
        return measurements.reduce((max, currentItem) => {
            return max > currentItem[format] ? max : currentItem[format];
        }, -Infinity);
    }

    private _concatAllValidMeasurements(): Measurement[] {
        return this._chartDataItems
            .reduce(
                (allMeasurements: Measurement[], currentItem: ChartDataItem) => [
                    ...allMeasurements,
                    currentItem.practitioner,
                    currentItem.benchmarkGgdRegion,
                    currentItem.benchmarkNl,
                ],
                [],
            )
            .filter(measurement => measurement !== null);
    }

    private _fillData(data: MeasurementVariableData): void {
        const { label } = data;
        const { practitioner, benchmarkNl, benchmarkGgdRegion } = data.measurements;

        this._chartDataItems = practitioner.map((p, index) => ({
            label: p.label,
            practitioner: p,
            benchmarkNl: benchmarkNl[index] ?? null,
            benchmarkGgdRegion: benchmarkGgdRegion[index] ?? null,
        }));
        this._yAxisLabel = label;
    }
}
