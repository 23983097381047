// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-view--exporting {
  page-break-inside: avoid;
  max-width: 1024px;
}`, "",{"version":3,"sources":["webpack://./src/app/authorized/components/chapter-trends/chart-view/chart-view.component.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;EACA,iBAAA;AAAR","sourcesContent":[".chart-view {\n    &--exporting {\n        page-break-inside: avoid;\n        max-width: 1024px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
