import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { DEFAULT_DECIMALS, Measurement, MeasurementVariableData } from "src/app/shared/entities";

import { Format } from "../format";

import { ChapterTrendsChartTablewViewPivotDefition } from "./table-view.pivot";

interface ItemData {
    label: string;
    practitioner: Measurement;
    benchmarkNl: Measurement;
    benchmarkGgdRegion: Measurement;
}

type ItemsData = ItemData[];

@Component({
    selector: "app-trends-table-view",
    templateUrl: "./table-view.component.html",
    styleUrls: ["./table-view.component.scss"],
})
export class ChapterTrendsTableViewComponent implements OnInit, OnChanges {
    @Input() format: Format = "";
    @Input() data: MeasurementVariableData | null = null;

    public _itemsData: ItemsData = [];

    readonly defaultDecimals = DEFAULT_DECIMALS;

    constructor(public _pivotDef: ChapterTrendsChartTablewViewPivotDefition) {}

    ngOnInit(): void {
        if (this.data) {
            this._fillItems(this.data);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this._fillItems(this.data);
        }
    }

    private _fillItems(data: MeasurementVariableData): void {
        const { practitioner, benchmarkNl, benchmarkGgdRegion } = data.measurements;

        this._itemsData = practitioner.map((p, index) => ({
            label: p.label,
            practitioner: p,
            benchmarkNl: benchmarkNl[index] ?? Object.create(null),
            benchmarkGgdRegion: benchmarkGgdRegion[index] ?? Object.create(null),
        }));
    }
}
