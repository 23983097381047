import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { INavNode } from "@logex/framework/lg-application";

import { Subsets, createNavigation } from "../../shared/entities";
import { ConfigurationService } from "@sharedservices";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    private _navigation = new BehaviorSubject<INavNode[]>([]);
    public navigation$ = this._navigation.asObservable();

    constructor(private _configuration: ConfigurationService) {}

    fillWithSubsets(subsets: Subsets): void {
        const navigation = this._createNavigation(subsets);
        this._navigation.next(navigation);
        this._navigation.complete();
    }

    private _createNavigation(subsets: Subsets): INavNode[] {
        const registryName = this._configuration.getConfig().registry.name;
        return createNavigation(registryName, subsets);
    }
}
