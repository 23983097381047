import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { Chapter, ResourceState } from "src/app/shared/entities";

import { ResourceService } from "./resource.service";

type ChapterDetailResourcesObservable = Observable<ResourceState<Chapter>>;

export interface ChapterDetailLoadingArgs {
    subsetId: number;
    datasetId: number;
    chapterId: number;
}

@Injectable({
    providedIn: "root",
})
export class ChapterService {
    constructor(private _resourceService: ResourceService) {}

    getDetail(args: ChapterDetailLoadingArgs): ChapterDetailResourcesObservable {
        const url = [
            "/descriptives/subsets/",
            args.subsetId,
            "/datasets/",
            args.datasetId,
            "/chapters/",
            args.chapterId,
        ].join("");

        // TODO - referenceProfile and year is hardcoded now but not in future (GLI has no year filter)
        return this._resourceService.get<Chapter>(url, {
            referenceProfile: "Default",
            year: "2020",
        });
    }
}
