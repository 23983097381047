import { Component, inject } from "@angular/core";
import { UserProfileService } from "./shared/services/user-profile.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    title = "Codman Descriptives";

    private _userProfileService = inject(UserProfileService);

    constructor() {
        this._userProfileService.init();
    }
}
