import { Component, Input, OnInit } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";

import { ErrorCode } from "src/app/shared/entities/error-code";

@Component({
    selector: "app-error-msg",
    templateUrl: "./error-msg.component.html",
    styleUrls: ["./error-msg.component.scss"],
})
export class ErrorMsgComponent implements OnInit {
    @Input() errTranslateCode: ErrorCode = "";
    @Input() errTranslateSection = "";
    @Input() errText = "";

    constructor(private _lgTranslateService: LgTranslateService) {}

    ngOnInit(): void {
        if (this.errText && (this.errTranslateCode || this.errTranslateSection)) {
            console.warn(
                "I will ignore passed errTranslateCode and errTranslateSection attributes if errText attribute is set.",
            );
        }

        if (!this.errText) {
            const translateId = `APP._ErrMsg.${this.errTranslateSection}.${this.errTranslateCode}`;
            this.errText = this._lgTranslateService.translate(translateId);
        }
    }
}
