// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    Workaround caused by this issue
    https://github.com/angular/angular-cli/issues/11451#issuecomment-424715627
    specific definition to be configured in angular.json and compilerconfig.json
*/
.breadcrumb-divider {
  margin-left: -64px;
  width: 100vw;
  border-bottom: 1px solid #e5e5e5;
}

.breadcrumb-row--padding {
  padding: 12px 24px 0;
}

.authorized-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  padding: 0 24px;
  box-sizing: border-box;
}

.authorized-page__content {
  flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./node_modules/@logex/framework/assets/styles/themes/mrdm/theme.scss","webpack://./src/app/authorized/authorized.component.scss"],"names":[],"mappings":"AACA;;;;CAAA;ACCA;EACI,kBAAA;EACA,YAAA;EACA,gCAAA;AAIJ;;AADA;EACI,oBAAA;AAIJ;;AADA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;AAIJ;;AADA;EACI,cAAA;AAIJ","sourcesContent":["$theme: \"mrdm\";\n/*\n    Workaround caused by this issue\n    https://github.com/angular/angular-cli/issues/11451#issuecomment-424715627\n    specific definition to be configured in angular.json and compilerconfig.json\n*/\n","@import \"definitions\";\n\n.breadcrumb-divider {\n    margin-left: -64px;\n    width: 100vw;\n    border-bottom: 1px solid #e5e5e5;\n}\n\n.breadcrumb-row--padding {\n    padding: $spacing2 $spacing5 0;\n}\n\n.authorized-page {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n    overflow-x: auto;\n    padding: 0 $spacing5;\n    box-sizing: border-box;\n}\n\n.authorized-page__content {\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
