import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map, tap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { MatomoConfiguration } from "@utilTracking";

import { Configuration, updateConfiguration } from "../entities/configuration";
import { ConfigurationService } from "./configuration.service";

@Injectable({
    providedIn: "root",
})
export class ConfigurationLoaderService {
    private _config?: Promise<Configuration | undefined>;

    constructor(
        private _http: HttpClient,
        private _configuration: ConfigurationService,
        private _matomoConfiguration: MatomoConfiguration,
    ) {}

    loadConfiguration(): Promise<Configuration | undefined> {
        if (!this._config) {
            this._config = this._http
                .get<Configuration>(environment.envSettings)
                .pipe(
                    map(config => updateConfiguration(config)),
                    tap(config => {
                        this._matomoConfiguration.configure(config);
                        this._configuration.complete(config);
                    }),
                )
                .toPromise();
        }

        return this._config;
    }
}
