import { Platform } from "@angular/cdk/platform";
import { Component, inject, Inject, OnInit } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { skip } from "rxjs/operators";

import { SidebarHelpLinkItem } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { ErrorService, UserService } from "./services";
import { LG_USER_INFO } from "@logex/framework/lg-application";

@Component({
    selector: "app-authorized",
    templateUrl: "./authorized.component.html",
    styleUrls: ["./authorized.component.scss"],
})
export class AuthorizedComponent implements OnInit {
    private _userService = inject(UserService);
    private _errorService = inject(ErrorService);
    private _translateService = inject(LgTranslateService);
    private _platform = inject(Platform);
    public _userInfo = inject(LG_USER_INFO);

    _isBrowserUnsupported = this._platform.TRIDENT || this._platform.EDGE;
    _contentReady = false;
    _errMsg$: Observable<string> = this._errorService.getErrorMessge();
    _supportLink = "https://support.mrdm.com/nl/rivm/gli/handleiding-spiegelinformatie-codman";
    _additionalItems: SidebarHelpLinkItem[] = [
        {
            name: this._translateService.translate("APP._Sidebar.Contact"),
            url: "mailto: servicedesk@mrdm.nl",
            icon: "icon-faq",
        },
    ];

    ngOnInit(): void {
        combineLatest([this._userService.organizationId$, this._userService.agbCode$]).subscribe(
            ([providerId, agbcode]) => {
                this._contentReady = true;

                if (!providerId) {
                    const errMsg = this._translateService.translate(
                        "APP._ErrMsg.AccessValidation.ProviderMissing",
                    );
                    this._errorService.setErrorMessage(errMsg);
                    return;
                }

                if (!agbcode) {
                    const errMsg = this._translateService.translate(
                        "APP._ErrMsg.AccessValidation.AgbcodeMissing",
                    );
                    this._errorService.setErrorMessage(errMsg);
                }
            },
        );

        this._userService.organizationId$.pipe(skip(1)).subscribe(() => window.location.reload());
    }
}
