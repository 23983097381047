import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Chapters, ResourceState } from "src/app/shared/entities";

import { ResourceService } from "./resource.service";

type ChaptersResourcesObservable = Observable<ResourceState<Chapters>>;

@Injectable({
    providedIn: "root",
})
export class ChaptersService {
    constructor(private _resourceService: ResourceService) {}

    list(subsetId: number, datasetId: number): ChaptersResourcesObservable {
        return this._resourceService.get<Chapters>(
            `/descriptives/subsets/${subsetId}/datasets/${datasetId}/chapters`,
        );
    }
}
