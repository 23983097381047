import { Injectable } from "@angular/core";
import { ServerGatewayBase } from "@logex/framework/lg-application";
import { Observable } from "rxjs";

import { ConfigurationService } from "@sharedservices";
import {
    AuthorizationExtendedPermission,
    AuthorizationOrganization,
    AuthorizationPermission,
    AuthorizationProfile,
    OrganizationIdentifier,
} from "./auth-api.types";

@Injectable({
    providedIn: "root",
})
export class AuthApiService extends ServerGatewayBase {
    constructor(protected _configurationService: ConfigurationService) {
        super();

        const apiUrl = _configurationService.getConfig().authApiUrl;
        if (apiUrl) {
            this.setBaseUrl(apiUrl);
        } else {
            console.error("Authorization service API url missing");
        }
    }

    getProfile(): Observable<AuthorizationProfile> {
        return this._get<AuthorizationProfile>(`api/v3/user/profile`);
    }

    getOrganizations(
        params: { applicationInstance?: string } = {},
    ): Observable<AuthorizationOrganization[]> {
        return this.get<AuthorizationOrganization[]>(`api/v3/user/organizations`, { params });
    }

    getPermissions(
        params: {
            applicationInstance?: string;
            product?: string;
            organizationIdentifier?: number;
            organizationType?: OrganizationIdentifier;
        } = {},
    ): Observable<AuthorizationPermission[]> {
        return this.get<AuthorizationPermission[]>(`api/v3/user/permissions`, { params });
    }

    searchPermissions(
        params: {
            applicationInstances?: string[];
            products?: string[];
            organizations?: string[];
            organizationIdentifierType?: OrganizationIdentifier;
        } = {},
    ): Observable<AuthorizationExtendedPermission[]> {
        params.applicationInstances = params.applicationInstances ?? [];
        params.products = params.products ?? [];
        params.organizations = params.organizations ?? [];
        return this.post<AuthorizationExtendedPermission[]>(`api/v3/user/permissions/search`, {
            body: { ...params },
        });
    }

    checkOrganization(params: {
        organizationIdentifier: string;
        organizationType: OrganizationIdentifier;
    }): Observable<boolean> {
        return this.get<boolean>(`api/v3/access/organization`, { params });
    }
}
