import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { SidebarAccountComponent } from "./components/sidebar-account/sidebar-account.component";

@NgModule({
    exports: [SidebarAccountComponent],
    declarations: [SidebarAccountComponent],
    imports: [CommonModule, LgLocalizationModule, UiCoreModule],
})
export class CodmanSidebarModule {}
