import { Component, Input, OnInit } from "@angular/core";

import { IDropdownDefinition } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { ChapterPercentage, DEFAULT_DECIMALS } from "src/app/shared/entities";

import { ChapterPercentageTablePivotDefition } from "./chapter-percentage-table.pivot";
import { BenchmarkView } from "./chapter-percentage-table-benchmark";

@Component({
    selector: "app-chapter-percentage-table",
    templateUrl: "./chapter-percentage-table.component.html",
    styleUrls: ["./chapter-percentage-table.component.scss"],
})
export class ChapterPercentageTableComponent implements OnInit {
    @Input() exporting = false;
    @Input() data: ChapterPercentage | null = null;

    public _benchmarkView: BenchmarkView = "NL";
    public _benchmarkViewDropdownDefinition: IDropdownDefinition<BenchmarkView> | null = null;

    readonly defaultDecimals = DEFAULT_DECIMALS;

    constructor(
        public _pivotDef: ChapterPercentageTablePivotDefition,
        private _translateService: LgTranslateService,
    ) {}

    ngOnInit(): void {
        this._updateBenchmarkViewDropdownDefinition();
    }

    public _onBenchmarkViewDropdownChange(view: BenchmarkView): void {
        this._benchmarkView = view;
    }

    private _updateBenchmarkViewDropdownDefinition(): void {
        this._benchmarkViewDropdownDefinition = {
            groups: [
                {
                    entries: [
                        {
                            id: "NL",
                            name: this._translateService.translate("APP._Column.BenchmarkNL"),
                        },
                        {
                            id: "GGD",
                            name: this._translateService.translate("APP._Column.BenchmarkGGD"),
                        },
                    ],
                },
            ],
        };
    }
}
