import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { toNumber } from "@logex/framework/utilities";

import { Datasets, ResourceState } from "src/app/shared/entities";
import { ResetDestroyComponent } from "src/app/shared/bases";

import { DatasetsService } from "../../services";

@Component({
    selector: "app-datasets",
    templateUrl: "./datasets.component.html",
    styleUrls: ["./datasets.component.scss"],
})
export class DatasetsComponent
    extends ResetDestroyComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() subsetId: number | null = null;
    @Input() forceGhostUi = false;

    public _datasetId: number | null = null;
    public _datasets$: Observable<ResourceState<Datasets>> | null = null;

    private _defaultDatasetId: number | null = null;

    constructor(private _route: ActivatedRoute, private _datasetsService: DatasetsService) {
        super();
    }

    ngOnInit(): void {
        this._datasetId = toNumber(this._route.snapshot.queryParams["datasetId"]);

        if (this.subsetId && !this.forceGhostUi) {
            this._loadDatasets(this.subsetId);
        }

        this._observeRouteQueryParams();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isChanged = changes.subsetId || changes.forceGhostUi;

        if (isChanged) {
            this._reset();

            if (this.subsetId && !this.forceGhostUi) {
                this._loadDatasets(this.subsetId);
            }
        }
    }

    ngOnDestroy(): void {
        this._resetAndDestroy();
    }

    protected _ngOnReset(): void {
        this._datasetId = null;
        this._defaultDatasetId = null;
        this._datasets$ = null;
    }

    private _loadDatasets(subsetId: number): void {
        this._datasets$ = this._datasetsService.list(subsetId);
        this._datasets$.pipe(this._takeUntilReset()).subscribe(datasets => {
            this._defaultDatasetId = datasets?.data?.[0]?.id || 0;

            const queryParamDatasetId = toNumber(this._route.snapshot.queryParams.datasetId);
            this._datasetId = this._datasetId || queryParamDatasetId || this._defaultDatasetId;
        });
    }

    private _observeRouteQueryParams(): void {
        this._route.queryParams
            .pipe(
                map(params => toNumber(params["datasetId"])),
                distinctUntilChanged(),
                this._takeUntilDestroy(),
            )
            .subscribe((datasetId: number) => {
                this._datasetId = datasetId || this._defaultDatasetId;
            });
    }
}
