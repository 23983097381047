import { Component, Input, OnInit } from "@angular/core";

import { LgPanelGridDefinition } from "@logex/framework/lg-layout";
import {
    IDropdownDefinition,
    IQuickSettingsMenuChoice,
    QuickSettingsMenuType,
} from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { ChapterMeasurement, MeasurementVariableData } from "src/app/shared/entities";
import { Format } from "./format";

type View = "table" | "chart";

const DATA_TYPE_TO_FORMAT: Record<string, Format> = {
    MeasurementPercentage: "percentage",
    MeasurementAverage: "average",
    MeasurementCount: "count",
};
@Component({
    selector: "app-chapter-trends",
    templateUrl: "./chapter-trends.component.html",
    styleUrls: ["./chapter-trends.component.scss"],
})
export class ChapterTrendsComponent implements OnInit {
    @Input() exporting = false;
    @Input() data: ChapterMeasurement | null = null;

    public _gridDefinition: LgPanelGridDefinition = {
        columns: [{ size: 1, id: "top-left" }],
    };

    public _currentVariableData: MeasurementVariableData | null = null;
    public _currentVariableDropdownDefinition: IDropdownDefinition<number> | null = null;
    public _currentVariableIndex = 0;

    public _format: Format = "";

    public _viewQuickSettingsMenuDefition: IQuickSettingsMenuChoice[] | null = null;
    public _view: View = "chart";

    constructor(private _translateService: LgTranslateService) {}

    ngOnInit(): void {
        if (this.data) {
            this._updateFormat(this.data);
            this._updateCurrentVariableData(this.data);
            this._updateVariableDropdownDefinition(this.data);
            this._updateQuickSettingsMenuForView();
        }
    }

    public _onVariableDropdownChange(index: number): void {
        this._currentVariableIndex = index;

        if (this.data) {
            this._updateCurrentVariableData(this.data);
        }
    }

    public _onViewDropdownChange(view: View): void {
        this._view = view;
    }

    private _updateFormat(data: ChapterMeasurement): void {
        this._format = DATA_TYPE_TO_FORMAT[data.dataType] ?? "";
    }

    private _updateCurrentVariableData(data: ChapterMeasurement): void {
        this._currentVariableData = data.variableData[this._currentVariableIndex];
    }

    private _updateVariableDropdownDefinition(data: ChapterMeasurement): void {
        this._currentVariableDropdownDefinition = {
            groups: [
                {
                    entries: data.variableData.map((varData, index) => ({
                        id: index,
                        name: varData.label,
                    })),
                },
            ],
        };
    }

    private _updateQuickSettingsMenuForView(): void {
        this._viewQuickSettingsMenuDefition = [
            {
                type: QuickSettingsMenuType.Choice,
                icon: "icon-line-chart",
                onClick: () => (this._view = "chart"),
                name: this._translateService.translate("APP._Chart.Chart"),
            },
            {
                type: QuickSettingsMenuType.Choice,
                onClick: () => (this._view = "table"),
                icon: "icon-table",
                name: this._translateService.translate("APP._Chart.Table"),
            },
        ];
    }
}
