import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { ResourceState, Subsets } from "src/app/shared/entities";

import { ResourceService } from "./resource.service";

@Injectable({
    providedIn: "root",
})
export class SubsetsService {
    constructor(private _resourceService: ResourceService) {}

    list(): Observable<ResourceState<Subsets>> {
        // TODO - referenceProfile and year is hardcoded now but not in future (GLI has no year filter)
        return this._resourceService.get<Subsets>("/descriptives/subsets", {
            referenceProfile: "Default",
            year: "2020",
        });
    }
}
