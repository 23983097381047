import { INavNode } from "@logex/framework/lg-application";
import { REGISTRIES } from "./registry";
import { Subsets } from "./subset";

export function createNavigation(activeRegitryName: string, subsets: Subsets): INavNode[] {
    const navigation: INavNode[] = [];

    for (const registry of REGISTRIES) {
        let navNode;

        const { name } = registry;

        if (name === activeRegitryName) {
            const children = subsets.map(subset => {
                const id = `${subset.id}`;
                return createNavNode({
                    id,
                    path: `dashboard/subsets/${id}`,
                    name: subset.label,
                });
            });
            navNode = createNavNode({
                id: "root",
                name: name.toUpperCase(),
                path: "",
                children,
            });
        } else {
            navNode = createNavNode({ id: name.toUpperCase(), path: "" });
        }

        navigation.push(navNode);
    }

    return navigation;
}

function createNavNode({
    id,
    path,
    name,
    lid,
    children,
}: {
    id: string;
    name?: string;
    path?: string;
    lid?: string;
    children?: INavNode[];
}): INavNode {
    name = name == null ? id : name;
    path = path == null ? id : path;

    return {
        id,
        path,
        name,
        lid,
        anonymous: false,
        children: children || [],
    };
}
