import { Pipe, PipeTransform } from "@angular/core";

import { round } from "../utils/math";

@Pipe({ name: "fmtRound" })
export class FmtRoundPipe implements PipeTransform {
    transform(value: number, prec = 2, unit = ""): string {
        if (!Number.isFinite(value) || value === 0) {
            return "-";
        }

        const formattedValue = String(round(value, prec)).replace(".", ",");
        return formattedValue + (unit ? " " + unit : "");
    }
}
