import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { combineLatest } from "rxjs";
import { delay, filter, switchMap, take, tap } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";

import { Subsets } from "src/app/shared/entities";
import { ConfigurationService } from "@sharedservices";

import { UserService } from "../services";
import { DataExportService } from "../services/data-export.service";
import { PdfPrintService } from "../services/pdf-print.service";

@Component({
    selector: "app-export",
    templateUrl: "./export.component.html",
    styleUrls: ["./export.component.scss"],
})
export class ExportComponent implements OnInit {
    constructor(
        private _translateService: LgTranslateService,
        private _pdfPrintService: PdfPrintService,
        private _dataExportService: DataExportService,
        private _configurationService: ConfigurationService,
        private _userService: UserService,
    ) {}

    @ViewChild("_exportContainer", { static: false })
    _exportContainer: ElementRef<HTMLDivElement> | null = null;

    _subsets: Subsets = [];
    _exportTitle = "";
    _agbcode = "";
    _organizationName = "";

    _exportInProgress = false;
    _exportError = false;

    ngOnInit(): void {
        const registryName = this._configurationService.getConfig().registry.name.toUpperCase();
        this._exportTitle = `${registryName} ${this._translateService.translate(
            "APP._Export.RegistryExport",
        )}`;
    }

    _startExporting(): void {
        this._exportError = false;
        this._exportInProgress = true;

        combineLatest([
            this._dataExportService.export().pipe(filter(resourceState => resourceState.loaded)),
            this._userService.agbCode$,
            this._userService.organizations$,
            this._userService.organizationId$,
        ])
            .pipe(
                take(1),
                tap(([resourceState, agbcode, organizations, organizationId]) => {
                    this._subsets = resourceState.data || [];
                    this._agbcode = agbcode;
                    this._organizationName =
                        organizations.find(organization => organization.cicCode === organizationId)
                            ?.name ?? "";
                }),
            )
            .pipe(
                delay(0),
                switchMap(() =>
                    this._pdfPrintService.print(
                        this._exportContainer?.nativeElement as HTMLDivElement,
                        this._exportTitle,
                    ),
                ),
            )
            .subscribe({
                next: () => (this._exportInProgress = false),
                error: () => {
                    this._exportInProgress = false;
                    this._exportError = true;
                },
            });
    }
}
