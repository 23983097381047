import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { toNumber } from "lodash";
import { Observable } from "rxjs";

import { ResetDestroyComponent } from "src/app/shared/bases";
import { Chapter, ResourceState } from "src/app/shared/entities";

import { ChapterService, ChapterDetailLoadingArgs } from "../../services";

@Component({
    selector: "app-chapter-detail",
    templateUrl: "./chapter-detail.component.html",
    styleUrls: ["./chapter-detail.component.scss"],
})
export class ChapterDetailComponent extends ResetDestroyComponent implements OnChanges, OnDestroy {
    @Input() subsetId: number | null = null;
    @Input() datasetId: number | null = null;
    @Input() chapterId: number | null = null;

    public _chapterDetail$: Observable<ResourceState<Chapter>> | null = null;

    constructor(private _chapterService: ChapterService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.subsetId || changes.datasetId || changes.chapterId) {
            this._reload();
        }
    }

    ngOnDestroy(): void {
        this._resetAndDestroy();
    }

    protected _ngOnReset(): void {
        this._chapterDetail$ = null;
    }

    private _reload(): void {
        const subsetId = toNumber(this.subsetId);
        const datasetId = toNumber(this.datasetId);
        const chapterId = toNumber(this.chapterId);

        if (subsetId > 0 && datasetId > 0 && chapterId > 0) {
            this._reset();
            this._loadChapterDetail(subsetId, datasetId, chapterId);
        }
    }

    private _loadChapterDetail(subsetId: number, datasetId: number, chapterId: number): void {
        const args: ChapterDetailLoadingArgs = {
            subsetId,
            datasetId,
            chapterId,
        };

        this._chapterDetail$ = this._chapterService.getDetail(args);
    }
}
