import { Component } from "@angular/core";
import { Location } from "@angular/common";

import { useTranslationNamespace } from "@logex/framework/lg-localization";

import { Auth0AuthorizationService, Auth0User } from "@auth0";

@Component({
    selector: "app-access-denied",
    templateUrl: "./access-denied.component.html",
    styleUrls: ["./access-denied.component.scss"],
    providers: [useTranslationNamespace("APP._AccessDenied")],
    host: {
        class: "flex-flexible flexcol flexcol--full",
    },
})
export class AccessDeniedComponent {
    constructor(
        private _auth0Authorization: Auth0AuthorizationService,
        private _location: Location,
    ) {
        this._location.replaceState("/");
    }

    _logout(): void {
        this._auth0Authorization.logout();
    }

    _getUser(): Auth0User | undefined {
        return this._auth0Authorization.currentUserProfile;
    }
}
