export interface Auth0User {
    email: string;
    email_verified: boolean;
    name: string;
    nickname: string;
    picture: string;
    given_name: string;
    family_name: string;
    sub: string;
    updated_at: string;
    locale: string;

    "https://mrdm.io/agbcode": string;
    "https://mrdm.io/applications": string[];
    "https://mrdm.io/healthcareserviceproviders": string[];
}
