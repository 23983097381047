import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
    LgLocalizationModule,
    ReferenceTranslateCompiler,
    useMessageFormatLocales,
    LG_LOCALIZATION_SETTINGS,
} from "@logex/framework/lg-localization";

import { TranslateCompiler, TranslateModule } from "@ngx-translate/core";
import {
    ConsoleLogLevel,
    LgConsoleConfiguration,
    LG_APPLICATION_MULTI_EVENT_TRACERS,
} from "@logex/framework/core";
import {
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_NAVIGATION,
    LG_USER_INFO,
    LgApplicationModule,
    LgMatTrackingService,
    LG_MATOMO_CONFIGURATION,
    LG_USERFLOW_SERVICE,
    LgUserflowService,
    LG_AUTHENTICATION_SERVICE,
} from "@logex/framework/lg-application";
import { UiCoreModule } from "@logex/framework/ui-core";

import { Auth0InterceptorService, AUTH0_CONFIG, GliAuthorizationModule } from "@auth0";

import {
    LocalizationSettings,
    ConfigurationService,
    ConfigurationLoaderService,
} from "@sharedservices";
import { createDefaultUser, LgAppConfiguration, dummySession } from "./shared/entities";

import { AuthorizedModule } from "./authorized/authorized.module";
import { NavigationService } from "./authorized/services/navigation.service";

import { AccessDeniedComponent } from "./access-denied/access-denied.component";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LgLocalStorageFwUiStateService, LG_FW_UI_STATE_SERVICE } from "@logex/framework/lg-layout";

import { CodmanApp } from "@auth";
import { MatomoConfiguration } from "@utilTracking";
import { UserProfileService } from "./shared/services/user-profile.service";
import { Auth0AuthenticationService } from "src/libs/auth0/lib/auth0-authentication.service";

const productId: CodmanApp = "descriptives";
@NgModule({
    declarations: [AppComponent, AccessDeniedComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        UiCoreModule,
        GliAuthorizationModule.forRoot(),
        LgLocalizationModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization",
        }),
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler },
        }),
        AuthorizedModule,
        LgApplicationModule,
    ],
    providers: [
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB"]),
        {
            provide: APP_INITIALIZER,
            useFactory: (loader: ConfigurationLoaderService) => () => loader.loadConfiguration(),
            deps: [ConfigurationLoaderService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: "nl-NL",
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: LocalizationSettings,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true,
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: (configuration: ConfigurationService) =>
                new Promise((resolve, reject) =>
                    configuration.onComplete(config => resolve(config.auth0)),
                ),
            deps: [ConfigurationService],
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: Auth0AuthenticationService,
        },
        {
            provide: LG_USER_INFO,
            useValue: createDefaultUser(),
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new LgAppConfiguration(),
        },
        {
            provide: LG_APP_INFO,
            useFactory: (configuration: ConfigurationService) => ({
                environment: configuration.getConfig().environment,
                fullAppName: "Codman Descriptives",
                productId,
                toolInstanceName: "ApplicationInstance.CodmanDescriptives.NL",
                versionNumber: "0.0.1",
                overrideCurrencyPrefix: "",
                overrideCurrencySuffix: "",
                doNotDoGaTracking: () => false,
                isProduction: () => configuration.getConfig().environment === "prod",
            }),
            deps: [ConfigurationService],
        },
        {
            provide: LG_APP_SESSION,
            useValue: dummySession,
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (navigation: NavigationService) => navigation.navigation$,
            deps: [NavigationService],
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useExisting: LgLocalStorageFwUiStateService,
        },
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                config.logger("Logex.Application", ConsoleLogLevel.Debug);
                return config;
            },
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration,
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true,
        },
        {
            provide: LG_USERFLOW_SERVICE,
            useExisting: LgUserflowService,
        },
        UserProfileService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
