// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    Workaround caused by this issue
    https://github.com/angular/angular-cli/issues/11451#issuecomment-424715627
    specific definition to be configured in angular.json and compilerconfig.json
*/
.chapter-count-table {
  border: 1px solid #cccccc;
  border-radius: 4px;
  page-break-inside: avoid;
}`, "",{"version":3,"sources":["webpack://./node_modules/@logex/framework/assets/styles/themes/mrdm/theme.scss","webpack://./src/app/authorized/components/chapter-count-table/chapter-count-table.component.scss"],"names":[],"mappings":"AACA;;;;CAAA;ACCA;EACI,yBAAA;EACA,kBAAA;EACA,wBAAA;AAIJ","sourcesContent":["$theme: \"mrdm\";\n/*\n    Workaround caused by this issue\n    https://github.com/angular/angular-cli/issues/11451#issuecomment-424715627\n    specific definition to be configured in angular.json and compilerconfig.json\n*/\n","@import \"definitions\";\n\n.chapter-count-table {\n    border: 1px solid $gray-color-20;\n    border-radius: 4px;\n    page-break-inside: avoid;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
