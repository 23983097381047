import { MonoTypeOperatorFunction, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export abstract class ResetComponent {
    private _resetToken = new Subject<void>();
    private _resetToken$ = this._resetToken.asObservable();

    protected _reset(): void {
        this._resetToken.next();

        if (typeof this._ngOnReset === "function") {
            this._ngOnReset();
        }
    }

    protected _ngOnReset?(): void;

    protected _takeUntilReset<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this._resetToken$);
    }
}
