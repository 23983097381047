import { RegistryTenant } from "./auth.types";
import { DICA_REGISTRIES } from "./registries";

const REGISTRY_TENANTS_LOOKUP: { [tenantId in RegistryTenant]: string[] } = {
    DICA: DICA_REGISTRIES,
    RIVM: ["GLI"],
};

let TENANT_LOOKUP: { [registryId: string]: RegistryTenant } = {};

for (const tenantId of Object.getOwnPropertyNames(REGISTRY_TENANTS_LOOKUP) as RegistryTenant[]) {
    TENANT_LOOKUP = REGISTRY_TENANTS_LOOKUP[tenantId].reduce((tenantLookup, registryId) => {
        tenantLookup[registryId] = tenantId;
        return tenantLookup;
    }, TENANT_LOOKUP);
}

export { TENANT_LOOKUP };
