import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { Datasets, ResourceState } from "src/app/shared/entities";

import { ResourceService } from "./resource.service";

type DatasetsResourcesObservable = Observable<ResourceState<Datasets>>;

@Injectable({
    providedIn: "root",
})
export class DatasetsService {
    constructor(private _resourceService: ResourceService) {}

    list(subsetId: number): DatasetsResourcesObservable {
        return this._resourceService.get<Datasets>(`/descriptives/subsets/${subsetId}/datasets`);
    }
}
