import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";

import { AuthService } from "@auth";
import { CodmanEventTracer } from "@utilTracking";
import { LgMatTrackingService } from "@logex/framework/lg-application";
import { combineLatest } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class UserService {
    userProfile$ = this._authService.userProfile$;
    agbCode$ = this.userProfile$.pipe(map(userProfile => userProfile.metadata.AgbCode ?? ""));
    organizationId$ = this._authService.organizationId$;
    organizations$ = this.userProfile$.pipe(map(userProfile => userProfile.organizations));
    organizationIds$ = this.organizations$.pipe(
        map(organizations => organizations.map(org => String(org.cicCode))),
    );

    constructor(
        private _authService: AuthService,
        private _tracer: CodmanEventTracer,
        private _matomo: LgMatTrackingService,
    ) {
        this._startTracking();
    }

    private _startTracking(): void {
        this.userProfile$.subscribe(userProfile => {
            this._matomo.setUserID(userProfile.email);
        });

        combineLatest([this.organizationId$, this.userProfile$]).subscribe(
            ([organizationId, userProfile]) => {
                const organization = userProfile.organizations.find(
                    org => org.cicCode === organizationId,
                );

                if (organization) {
                    this._tracer.setOrganization(organization.organizationId);
                    this._tracer.setOrganizationName(organization.name);
                    this._tracer.setPosition(organization.position);
                }
            },
        );
    }
}
