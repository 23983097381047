import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PlatformModule } from "@angular/cdk/platform";

import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { LgChartsModule } from "@logex/framework/lg-charts";

import { CodmanSidebarModule } from "@codmansidebar";

import { SharedModule } from "../shared/shared.module";

import { AuthorizedComponent } from "./authorized.component";
import { AuthorizedRoutingModule } from "./authorized-routing.module";

import { SubsetsComponent } from "./subsets/subsets.component";

import { ChapterAverageTableComponent } from "./components/chapter-average-table/chapter-average-table.component";
import { ChapterCountTableComponent } from "./components/chapter-count-table/chapter-count-table.component";
import { ChapterPercentageTableComponent } from "./components/chapter-percentage-table/chapter-percentage-table.component";
import { ChapterTrendsComponent } from "./components/chapter-trends/chapter-trends.component";
import { ChapterTrendsTableViewComponent } from "./components/chapter-trends/table-view/table-view.component";
import { ChapterTrendsChartViewComponent } from "./components/chapter-trends/chart-view/chart-view.component";
import { ChapterDetailComponent } from "./components/chapter-detail/chapter-detail.component";
import { ChapterGroupComponent } from "./components/chapter-group/chapter-group.component";
import { ChaptersComponent } from "./components/chapters/chapters.component";
import { ChaptersListComponent } from "./components/chapters-list/chapters-list.component";
import { DatasetsComponent } from "./components/datasets/datasets.component";
import { DatasetsListComponent } from "./components/datasets-list/datasets-list.component";
import { ErrorMsgComponent } from "./components/error-msg/error-msg.component";
import { PercentageTableBarchartComponent } from "./components/chapter-percentage-table/barchart/barchart.component";
import { SubsetComponent } from "./components/subset/subset.component";
import { ExportComponent } from "./export/export.component";

@NgModule({
    declarations: [
        AuthorizedComponent,
        SubsetsComponent,
        SubsetComponent,
        DatasetsComponent,
        DatasetsListComponent,
        ChapterAverageTableComponent,
        ChapterCountTableComponent,
        ChapterPercentageTableComponent,
        ChapterTrendsComponent,
        ChapterTrendsTableViewComponent,
        ChapterTrendsChartViewComponent,
        ChapterDetailComponent,
        ChapterGroupComponent,
        ChaptersComponent,
        ChaptersListComponent,
        ErrorMsgComponent,
        PercentageTableBarchartComponent,
        ExportComponent,
    ],
    imports: [
        CommonModule,
        UiCoreModule,
        RouterModule,
        AuthorizedRoutingModule,
        LgLayoutModule,
        LgLocalizationModule,
        LgPivotTableModule,
        LgChartsModule,
        CodmanSidebarModule,
        SharedModule,
        PlatformModule,
    ],
})
export class AuthorizedModule {}
