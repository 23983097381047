import { PivotProviderBase, ILogexPivotDefinition } from "@logex/framework/lg-pivot";

export abstract class DefaultPivotDefinition extends PivotProviderBase {
    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            defaultOrderBy: "",
            levelId: "nodes",
        };
    }
}
