import { MonoTypeOperatorFunction, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ResetComponent } from "./reset.component";

export abstract class ResetDestroyComponent extends ResetComponent {
    private _destroyToken = new Subject<void>();
    private _destroyToken$ = this._destroyToken.asObservable();

    protected _takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this._destroyToken$);
    }

    protected _resetAndDestroy(): void {
        this._reset();

        this._destroyToken.next();
        this._destroyToken.complete();
    }
}
