import { IAppSession } from "@logex/framework/lg-application";

export const dummySession: IAppSession = {
    clientId: 10003,
    client: {
        id: 123,
        code: "123",
        name: "Test",
    },
    scenarioId: 1,
};
