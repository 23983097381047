import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { FmtRoundPipe } from "./pipes/fmt-round.pipe";
import { SnackbarComponent } from "./components/snackbar/snackbar.component";
import { FooterComponent } from "./components/footer/footer.component";

@NgModule({
    declarations: [FmtRoundPipe, SnackbarComponent, FooterComponent],
    imports: [CommonModule, LgLocalizationModule, UiCoreModule],
    exports: [FmtRoundPipe, SnackbarComponent, FooterComponent],
})
export class SharedModule {}
