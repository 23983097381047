import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { toNumber } from "@logex/framework/utilities";

import { ResetDestroyComponent } from "src/app/shared/bases";
import { ErrorService, NavigationService, SubsetsService } from "../services";
import { Subset } from "src/app/shared/entities";

@Component({
    selector: "app-subsets",
    templateUrl: "./subsets.component.html",
    styleUrls: ["./subsets.component.scss"],
})
export class SubsetsComponent extends ResetDestroyComponent implements OnInit, OnDestroy {
    public _subset: Subset | null = null;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _subsetsService: SubsetsService,
        private _errorService: ErrorService,
        private _translateService: LgTranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this._loadSubsets();
    }

    ngOnDestroy(): void {
        this._resetAndDestroy();
    }

    private _loadSubsets(): void {
        combineLatest([
            this._subsetsService.list().pipe(filter(subsetsState => subsetsState.loaded)),
            this._route.params.pipe(
                map(params => toNumber(params["subsetId"])),
                distinctUntilChanged(),
            ),
        ])
            .pipe(this._takeUntilDestroy())
            .subscribe(([subsetsResource, subsetId]) => {
                if (subsetsResource.hasError) {
                    const errMsg =
                        subsetsResource.status === 403
                            ? this._translateService.translate("APP._ErrMsg.Subsets.NoAccess")
                            : this._translateService.translate("APP._ErrMsg.Subsets.InternalError");
                    this._errorService.setErrorMessage(errMsg);
                    return;
                }

                const subsets = subsetsResource.data || [];
                this._navigationService.fillWithSubsets(
                    subsets.filter(subset => !subset.lowPatientsCount),
                );

                const firstSubset = subsets[0];
                if (!subsetId && firstSubset) {
                    this._router.navigate(["dashboard", "subsets", firstSubset.id]);
                    return;
                }

                this._subset = subsets.find(subset => subset.id === subsetId) || null;
            });
    }
}
