import { Inject, Injectable } from "@angular/core";
import { AuthorizationOrganization } from "@auth";
import { IAppInfo, LG_APP_INFO } from "@logex/framework/lg-application";

import { Observable, ReplaySubject } from "rxjs";
import { shareReplay, map, first, distinctUntilChanged, switchMap, take } from "rxjs/operators";

import { AuthApiService } from "./auth-api.service";
import { AuthorizationProfile, OrganizationIdentifier } from "./auth-api.types";
import { AuthRegistryInfo, CodmanApp } from "./auth.types";
import { filterProductOrganizationsByAuthPerms } from "./organizations";
import { createProductCode, parseProductCode } from "./product-code";
import { getUniqRegistriesFromPermissions } from "./registries";

const APP_INSTANCE_LOOKUP: { [app in CodmanApp]: string } = {
    descriptives: "ApplicationInstance.CodmanDescriptives.NL",
    exploration: "ApplicationInstance.CodmanExploration.NL",
};

@Injectable({
    providedIn: "root",
})
export class AuthService {
    organizationIdentifier: OrganizationIdentifier = "Cic";

    private readonly _organizationId$ = new ReplaySubject<number>(1);
    readonly organizationId$ = this._organizationId$.pipe(distinctUntilChanged());

    private readonly _organizationName$ = new ReplaySubject<string>(1);
    readonly organizationName$ = this._organizationName$.pipe(distinctUntilChanged());

    readonly userProfile$ = this._getUserProfile();
    readonly grantedRegistries$ = this._getGrantedRegistries();
    readonly grantedOrganizations$ = this._getGrantedOrganizations();

    constructor(
        private _authApiService: AuthApiService,
        @Inject(LG_APP_INFO) private _lgAppInfo: IAppInfo,
    ) {
        const organizationId = localStorage.getItem("organizationId");
        this.switchOrganizationId(Number(organizationId));
    }

    getAllUserRegistries(organization?: number, app?: CodmanApp): Observable<AuthRegistryInfo[]> {
        const applicationInstances = [];

        if (app) {
            const appInstance = APP_INSTANCE_LOOKUP[app];
            applicationInstances.push(appInstance);
        }

        return this._authApiService
            .searchPermissions({
                applicationInstances,
                organizations: organization ? [organization.toString()] : undefined,
                organizationIdentifierType: this.organizationIdentifier,
            })
            .pipe(map(permissions => getUniqRegistriesFromPermissions(permissions)));
    }

    switchOrganizationId(organizationId: number): void {
        this.grantedOrganizations$.pipe(take(1)).subscribe(organizations => {
            let existingOrganization = organizations.find(
                organization => organization.cicCode === organizationId,
            );

            if (existingOrganization == null) {
                const defaultOrganizationId = organizations.find(
                    organization => organization.cicCode != null,
                );
                if (defaultOrganizationId == null) {
                    console.error("There is no organization with a valid CIC.");
                } else {
                    existingOrganization = defaultOrganizationId;
                }
            }

            if (existingOrganization != null) {
                this._organizationId$.next(existingOrganization.cicCode ?? 0);
                this._organizationName$.next(existingOrganization.name ?? "");

                if (existingOrganization.cicCode) {
                    localStorage.setItem("organizationId", String(existingOrganization.cicCode));
                } else {
                    localStorage.removeItem("organizationId");
                }
            }
        });
    }

    private _getUserProfile(): Observable<AuthorizationProfile> {
        return this._authApiService.getProfile().pipe(first(), shareReplay(1));
    }

    private _getGrantedRegistries(): Observable<string[]> {
        return this._organizationId$.pipe(
            switchMap(organizationId =>
                this._authApiService.getPermissions({
                    applicationInstance: this._lgAppInfo.toolInstanceName,
                    organizationIdentifier: organizationId,
                    organizationType: this.organizationIdentifier,
                }),
            ),
            map(permissions =>
                permissions
                    .map(permission => parseProductCode(permission.product)?.registryId)
                    .filter((registry): registry is string => registry != null),
            ),
            shareReplay(1),
        );
    }

    private _getGrantedOrganizations(): Observable<AuthorizationOrganization[]> {
        return this._getUserProfile()
            .pipe(
                switchMap(userProfile => {
                    const organizations = userProfile.organizations ?? [];

                    return this._authApiService
                        .searchPermissions({
                            applicationInstances: [
                                APP_INSTANCE_LOOKUP[this._lgAppInfo.productId as CodmanApp],
                            ],
                            organizations: organizations
                                .filter(organization => !!organization.cicCode)
                                .map(organisation => "" + organisation.cicCode),
                            organizationIdentifierType: this.organizationIdentifier,
                        })
                        .pipe(
                            take(1),
                            map(perms =>
                                filterProductOrganizationsByAuthPerms(
                                    createProductCode("GLI"), // FIXME only for GLI now , it will be read from URL in future
                                    organizations,
                                    perms,
                                ),
                            ),
                        );
                }),
            )
            .pipe(shareReplay(1));
    }
}
