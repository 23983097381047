export interface Registry {
    url: string;
    apiUrl: string;
    name: string;
}

export type Registries = Registry[];

export const REGISTRIES: Registries = [
    {
        url: "https://gli-descriptives-test.valubase.nl",
        apiUrl: "https://gli-reporting-test.valubase.nl",
        name: "gli",
    },
];
