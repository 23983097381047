import { Inject, Injectable } from "@angular/core";

import { IApplicationEventTracer, LG_APPLICATION_EVENT_TRACER } from "@logex/framework/core";
import { LgMatTrackingService } from "@logex/framework/lg-application";

import { MatomoConfiguration } from "./matomo-configuration.service";

const Category = "Codman";

@Injectable({
    providedIn: "root",
})
export class CodmanEventTracer {
    private readonly _matomoConfig = this._matomoConfiguration.configuration?.matomo;

    constructor(
        @Inject(LG_APPLICATION_EVENT_TRACER) private _tracer: IApplicationEventTracer,
        private _matomo: LgMatTrackingService,
        private _matomoConfiguration: MatomoConfiguration,
    ) {}

    setOrganization(organization: number): void {
        this._matomoConfiguration.setOrganizationId(organization);
        if (this._matomoConfig?.organizationDimensionId) {
            this._matomo.setCustomDimension(
                this._matomoConfig.organizationDimensionId,
                organization,
            );
            this._tracer.trackEvent(Category, "organization", organization.toString());
        }
    }

    setOrganizationName(organizationName: string): void {
        this._matomoConfiguration.setOrganizationName(organizationName);
        if (this._matomoConfig?.organizationNameDimensionId) {
            this._matomo.setCustomDimension(
                this._matomoConfig.organizationNameDimensionId,
                organizationName,
            );
            this._tracer.trackEvent(Category, "organizationName", organizationName);
        }
    }

    setTenant(tenant: string): void {
        this._matomoConfiguration.setTenant(tenant);
        if (this._matomoConfig?.tenantDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.tenantDimensionId, tenant);
            this._tracer.trackEvent(Category, "tenant", tenant);
        }
    }

    setPosition(position: string): void {
        this._matomoConfiguration.setUserPosition(position);
        if (this._matomoConfig?.positionDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.positionDimensionId, position);
            this._tracer.trackEvent(Category, "position", position);
        }
    }

    setRegistry(registry: string): void {
        if (this._matomoConfig?.registryDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.registryDimensionId, registry);
            this._tracer.trackEvent(Category, "registryUri", registry);
        }
    }

    setPage(page: string): void {
        if (this._matomoConfig?.pageDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.pageDimensionId, page);
            this._tracer.trackEvent(Category, "pageUri", page);
        }
    }
}
