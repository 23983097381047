import { Auth0Config } from "@auth0";
import { IMatomo } from "@utilTracking";
import { Registry } from "./registry";

export interface Configuration {
    environment: "test" | "accept" | "prod";
    auth0: Auth0Config;
    registry: Registry;
    authApiUrl: string;
    matomo: IMatomo;
}

export function updateConfiguration(configuration: Configuration): Configuration {
    const auth0 = {
        ...configuration.auth0,
        redirectUri: location.origin,
    };

    return {
        ...configuration,
        auth0,
    };
}

export const DEFAULT_DECIMALS = 1;
