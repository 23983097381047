import { Injectable } from "@angular/core";
import { Configuration } from "../entities/configuration";

type CompleteCallback = (config: Configuration) => void;
@Injectable({
    providedIn: "root",
})
export class ConfigurationService {
    private _cbkOnComplete: CompleteCallback[] = [];
    private _config!: Configuration;

    getConfig(): Configuration {
        return this._config;
    }

    complete(config: Configuration): void {
        this._config = config;
        this._emitCompleted();
    }

    onComplete(cbk: CompleteCallback): void {
        this._cbkOnComplete.push(cbk);

        if (this._config) {
            this._emitCompleted();
        }
    }

    private _emitCompleted(): void {
        if (this._cbkOnComplete.length) {
            this._cbkOnComplete.forEach(cbk => cbk(this._config));
            this._cbkOnComplete = [];
        }
    }
}
