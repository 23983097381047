export class LgAppConfiguration {
    instance = "";
    environment = "";
    mamangementUrl = "";
    applicationRoot = "";
    domain = "";
    clientId = "";
    audience = "";
    scope = "";
}
