import { Component, Input } from "@angular/core";

import { Dataset, Datasets, ResourceState } from "src/app/shared/entities";

@Component({
    selector: "app-datasets-list",
    templateUrl: "./datasets-list.component.html",
    styleUrls: ["./datasets-list.component.scss"],
})
export class DatasetsListComponent {
    @Input() datasets: ResourceState<Datasets> | null = null;
    @Input() datasetId: number | null = null;
    @Input() forceGhostUi = false;

    _trackBy(index: number, dataset: Dataset): number {
        return dataset.id;
    }
}
