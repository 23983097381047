import { AuthorizationExtendedPermission } from "./auth-api.types";

export type CodmanApp = "descriptives" | "exploration";

export type RegistryTenant = "DICA" | "RIVM";

export interface AuthRegistryInfo {
    tenant: string;
    registryId: string;
    product: string;
    permissions: AuthorizationExtendedPermission[];
}

export type Tenants = {
    [tenantId: string]: AuthRegistryInfo[];
};
