import { TemplatePortal } from "@angular/cdk/portal";
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from "@angular/core";
import { LgTooltipService, TooltipApi } from "@logex/framework/ui-core";

@Component({
    selector: "app-snackbar",
    templateUrl: "./snackbar.component.html",
    styleUrls: ["./snackbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent implements AfterViewInit {
    @Input("text") text = "";
    @Input("type") type: "regular" | "warning" | "error" = "regular";
    @Input("showClose") showClose = true;
    @ViewChild("snackbar") _snackbarTemplate: TemplateRef<any> | null = null;
    private _tooltipApi: TooltipApi | null = null;

    constructor(
        private _tooltipService: LgTooltipService,
        private _elementRef: ElementRef,
        private _viewContainerRef: ViewContainerRef,
    ) {}

    ngAfterViewInit(): void {
        this._tooltipApi = this._tooltipService.create({
            content: () =>
                new TemplatePortal(
                    this._snackbarTemplate as TemplateRef<any>,
                    this._viewContainerRef,
                ),
            tooltipClass: `snackbar snackbar--${this.type}`,
            position: "right-bottom",
            target: this._elementRef,
            stay: true,
        });

        this._tooltipApi?.show();
    }

    onClose(): void {
        this._tooltipApi?.hide();
    }
}
