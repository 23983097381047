import { Injectable } from "@angular/core";

import * as printJS from "print-js";

import { Observable, Subject } from "rxjs";

let exportId = 0;

@Injectable({
    providedIn: "root",
})
export class PdfPrintService {
    print(elm: HTMLElement, title?: string): Observable<void> {
        const printing = new Subject<void>();

        this._replaceStylesForIE(elm);

        const originTitle = document.title;
        document.title = title || "";

        if (!elm.id) {
            elm.id = `export${++exportId}`;
        }

        const css = this._getAllCssLinks();
        const styles = this._getAllStyles();
        const icons = this._getAllIconHTML();

        const complete = (): void => {
            printing.next();
            printing.complete();
        };

        printJS({
            printable: elm.id,
            type: "html",
            targetStyles: ["*"],
            style: `@media print {\n ${styles.join("\n")} \n}`,
            css,
            header: icons.join(""),
            scanStyles: false,
            documentTitle: title,
            onError: complete,
            onLoadingEnd: complete,
            onPrintDialogClose: () => {
                document.title = originTitle;
            },
        });

        return printing.asObservable();
    }

    private _getAllCssLinks(): string[] {
        return Array.from(
            document.head.querySelectorAll<HTMLLinkElement>("link[rel=stylesheet]"),
        ).map(a => a.href + `" media="print`);
    }

    private _getAllStyles(): string[] {
        return Array.from(document.head.querySelectorAll<HTMLStyleElement>("style")).map(
            s => s.innerText,
        );
    }

    private _getAllIconHTML(): string[] {
        return Array.from(document.body.querySelectorAll("div.lg-icon-atlas")).map(
            d => d.outerHTML,
        );
    }

    private _replaceStylesForIE(elementToPrint: HTMLElement): void {
        const withPath = Array.from(elementToPrint.querySelectorAll("g[clip-path]"));

        for (const el of withPath) {
            const url = el.getAttribute("clip-path");
            el.setAttribute("clip-path", (url || "").replace(/([^(]+#)/, "#").replace(/"/g, ""));
        }
    }
}
