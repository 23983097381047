import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@auth";
import { Auth0Guard } from "@auth0";

import { AccessDeniedComponent } from "./access-denied/access-denied.component";

const routes: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import("./authorized/authorized.module").then(m => m.AuthorizedModule),
        canLoad: [Auth0Guard, AuthGuard],
        canActivate: [Auth0Guard, AuthGuard],
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
