import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Chapter, Chapters, ResourceState } from "src/app/shared/entities";

@Component({
    selector: "app-chapters-list",
    templateUrl: "./chapters-list.component.html",
    styleUrls: ["./chapters-list.component.scss"],
})
export class ChaptersListComponent implements OnChanges {
    @Input() subsetId: number | null = null;
    @Input() datasetId: number | null = null;
    @Input() chapters: ResourceState<Chapters> | null = null;
    @Input() selectedChapterIds: number[] = [];
    @Input() forceGhostUi = false;

    _selectedChapters: number[] = [];
    _chaptersInitialized = false;

    constructor(private _router: Router, private _route: ActivatedRoute) {}

    ngOnChanges(changes: SimpleChanges): void {
        const pageSwitched = this._chaptersInitialized && (changes.datasetId || changes.subsetId);
        if (pageSwitched) {
            this._closeAllChapters();
            return;
        }
        const pageLoaded = !this._chaptersInitialized && changes.datasetId && this.datasetId;
        const openedChaptersChanged = this._chaptersInitialized && changes.selectedChapterIds;
        if (pageLoaded || openedChaptersChanged) {
            this._setSelectedChapters(this.selectedChapterIds);
            this._chaptersInitialized = true;
        }
    }

    _trackBy(index: number, chapter: Chapter): number {
        return chapter.id;
    }

    _openChapter(chapterId: number): void {
        this._setChaptersInQueryParams([...this._selectedChapters, chapterId]);
    }

    _closeChapter(chapterId: number): void {
        const withoutClicked = this._selectedChapters.filter(chapter => chapter !== chapterId);
        this._setChaptersInQueryParams(withoutClicked.length === 0 ? null : withoutClicked);
    }

    private _setSelectedChapters(chapterIds: number[] | null): void {
        this._selectedChapters = chapterIds ?? [];
    }

    private _closeAllChapters(): void {
        this._setSelectedChapters([]);
        this._setChaptersInQueryParams(null);
    }

    private _setChaptersInQueryParams(chapters: number[] | null): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { chapters },
            queryParamsHandling: "merge",
        });
    }
}
