import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ErrorService {
    private _msg = new BehaviorSubject<string>("");
    private _msg$ = this._msg.asObservable();

    getErrorMessge(): Observable<string> {
        return this._msg$;
    }

    setErrorMessage(msg: string): void {
        this._msg.next(msg);
    }
}
