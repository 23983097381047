import { upperFirst } from "lodash";
import { RegistryTenant } from "./auth.types";
import { TENANT_LOOKUP } from "./tenant-lookup";

export function parseProductCode(
    productCode: string,
): { tenant: RegistryTenant; registryId: string } | null {
    const productCodes = productCode.split(".");
    if (productCodes[0] !== "Product") {
        return null;
    }
    const registryCode = productCodes[1];
    const registryId = parseProductCodeToRegistry(registryCode);

    const tenant = TENANT_LOOKUP[registryId];
    return tenant != null
        ? {
              tenant,
              registryId,
          }
        : null;
}

export function parseProductCodeToRegistry(productRegistryCode: string): string {
    // convert PascalCase to uppercase dashed (DlcaS -> DLCA-S)
    const regExp = new RegExp(/[A-Z]{1}[a-z]*/g);
    return (
        productRegistryCode
            .match(regExp)
            ?.map(code => code.toUpperCase())
            .join("-") ?? ""
    );
}

export function createProductCode(registryId: string): string {
    if (TENANT_LOOKUP[registryId] == null) {
        console.warn("Creating product code for unknown registry");
    }

    const regExp = new RegExp(/[A-Z]+/g);
    const registryCode = registryId
        .match(regExp)
        ?.map(code => code.toLowerCase())
        .map(upperFirst)
        .join("");
    return `Product.${registryCode}`;
}
