import { Component, Inject, inject } from "@angular/core";

import { map } from "rxjs/operators";

import { Auth0AuthorizationService } from "@auth0";
import { AuthService } from "@auth";
import { LG_USER_INFO } from "@logex/framework/lg-application";
import { User } from "src/app/shared/entities/user";

@Component({
    selector: "codman-sidebar-account",
    templateUrl: "./sidebar-account.component.html",
    styleUrls: ["./sidebar-account.component.scss"],
    host: {
        class: "lg-sidebar-account lg-sidebar-panel",
    },
})
export class SidebarAccountComponent {
    private _auth = inject(AuthService);
    private _auth0 = inject(Auth0AuthorizationService);

    constructor(@Inject(LG_USER_INFO) public _userInfo: User) {}

    _organizations$ = this._auth.grantedOrganizations$.pipe(
        map(organizations => organizations || []),
    );

    _organizationsInDropdown$ = this._organizations$.pipe(
        map(organizations => ({
            groups: [
                {
                    entries: organizations,
                },
            ],
            entryId: "cicCode",
            entryName: "name",
        })),
    );

    _organizationId$ = this._auth.organizationId$;

    _user$ = this._auth.userProfile$.pipe(
        map(user => {
            if (!user) {
                return null;
            }

            return {
                name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : "",
                email: user.email,
            };
        }),
    );

    _switchOrganizationId(id: number): void {
        this._auth.switchOrganizationId(id);
    }

    _logout(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this._auth0.logout();
    }
}
