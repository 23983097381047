import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { map } from "rxjs/operators";
import { toNumber } from "@logex/framework/utilities";

import { Chapters, ResourceState } from "src/app/shared/entities";
import { ResetDestroyComponent } from "src/app/shared/bases";

import { ChaptersService } from "../../services";
import { Observable } from "rxjs";
@Component({
    selector: "app-chapters",
    templateUrl: "./chapters.component.html",
    styleUrls: ["./chapters.component.scss"],
})
export class ChaptersComponent
    extends ResetDestroyComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() subsetId: number | null = null;
    @Input() datasetId: number | null = null;
    @Input() forceGhostUi = false;

    public _chapters$: Observable<ResourceState<Chapters>> | null = null;

    _selectedChapters: number[] = [];

    constructor(private _route: ActivatedRoute, private _chaptersService: ChaptersService) {
        super();
    }

    ngOnInit(): void {
        this._observeRouteQueryParams();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isChanged = changes.subsetId || changes.datasetId || changes.forceGhostUi;

        if (isChanged) {
            this._reset();

            if (this.subsetId && this.datasetId && !this.forceGhostUi) {
                this._loadChapters(this.subsetId, this.datasetId);
            }
        }
    }

    ngOnDestroy(): void {
        this._resetAndDestroy();
    }

    protected _ngOnReset(): void {
        this._chapters$ = null;
    }

    private _loadChapters(subsetId: number, datasetId: number): void {
        this._chapters$ = this._chaptersService.list(subsetId, datasetId);
    }

    private _observeRouteQueryParams(): void {
        this._route.queryParamMap
            .pipe(
                map(params => params.getAll("chapters")),
                this._takeUntilDestroy(),
            )
            .subscribe(chapters => {
                this._selectedChapters = chapters.map(chapter => toNumber(chapter));
            });
    }
}
