import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { IHttpRequestOptions } from "@logex/framework/lg-application";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

import { createNewResource, Resource, ResourceState } from "src/app/shared/entities";

import { ApiService, IUrlParams } from "./api.service";

type CachedResource<T = any> = Resource<T>;

@Injectable({
    providedIn: "root",
})
export class ResourceService {
    private _resourceStore: Record<string, CachedResource> = {};

    constructor(private _apiService: ApiService) {}

    get<T>(
        url: string,
        urlParams: IUrlParams = {},
        options: IHttpRequestOptions = {},
        { noCache }: { noCache: boolean } = { noCache: false },
    ): Observable<ResourceState<T>> {
        const key = `${url}:${JSON.stringify(options)}`;

        if (!this._resourceStore[key]) {
            const resource = createNewResource<T>();
            resource.setAsLoading();

            this._apiService
                .getResourceData<T>(url, options, urlParams)
                .pipe(take(1))
                .subscribe(
                    data => {
                        if (data instanceof HttpResponse) {
                            resource.setAsLoaded(200, data.body);
                        } else {
                            resource.setAsLoaded(200, data);
                        }

                        if (noCache) {
                            delete this._resourceStore[key];
                        }
                    },
                    (err: HttpErrorResponse) => {
                        resource.setAsLoaded(err.status);
                        delete this._resourceStore[key];
                    },
                );

            this._resourceStore[key] = resource;
        }

        return this._resourceStore[key].getState();
    }
}
