import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";

import { Subset } from "src/app/shared/entities";
import { ConfigurationService } from "@sharedservices";
import { TENANT_LOOKUP } from "@auth";
import { CodmanEventTracer } from "@utilTracking";
import { ErrorService } from "../../services";

@Component({
    selector: "app-subset",
    templateUrl: "./subset.component.html",
    styleUrls: ["./subset.component.scss"],
})
export class SubsetComponent implements OnInit, OnChanges {
    @Input() subset: Subset | null = null;

    constructor(
        private _errorService: ErrorService,
        private _translateService: LgTranslateService,
        private _tracer: CodmanEventTracer,
        private _configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this._checkCurrentSubset();
        const registryName = this._configurationService.getConfig().registry.name.toUpperCase();
        if (registryName) {
            this._tracer.setRegistry(registryName);
            this._tracer.setTenant(TENANT_LOOKUP[registryName]);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.subset) {
            const page = changes.subset.currentValue?.label;
            if (page) {
                this._tracer.setPage(page);
            }
            this._checkCurrentSubset();
        }
    }

    private _checkCurrentSubset(): void {
        if (this.subset?.lowPatientsCount) {
            const errMsg = this._translateService.translate("APP._ErrMsg.Subsets.LowPatientsCount");
            this._errorService.setErrorMessage(errMsg);
        }
    }
}
