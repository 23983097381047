import { BehaviorSubject, Observable } from "rxjs";
import { ErrorCode } from "./error-code";

type ResourceData<T> = T | null;
interface ResourceStateShapePatch<T> {
    ok?: boolean;
    loaded?: boolean;
    loading?: boolean;
    status?: number;
    errorCode?: ErrorCode;
    hasError?: boolean;
    data?: ResourceData<T>;
}
interface ResourceStateShape<T> {
    ok: boolean;
    loaded: boolean;
    loading: boolean;
    status: number;
    errorCode: ErrorCode;
    hasError: boolean;
    data: ResourceData<T>;
}

export type ResourceState<T = unknown> = ResourceStateShape<ResourceData<T>>;

const HTTP_STATUS_TO_ERR_CODE: Record<number, ErrorCode> = {
    404: "NotFound",
    422: "DataNotFound",
    500: "InternalError",
};

export function createNewResource<T>(): Resource<T> {
    return new Resource();
}

export class Resource<T> {
    private _state: ResourceState<T> = this._createState();
    private _stateBS = new BehaviorSubject<ResourceState<T>>(this._state);
    private _stateBS$ = this._stateBS.asObservable();

    getState(): Observable<ResourceState<T>> {
        return this._stateBS$;
    }

    setAsLoading(): void {
        this._updateState({
            loading: true,
        });
    }

    setAsLoaded(status: number, data: ResourceData<T> = null): void {
        const ok = status >= 200 && status <= 299;
        const errorCode = ok ? "" : HTTP_STATUS_TO_ERR_CODE[status] ?? "Unknown";
        const hasError = !ok;

        this._updateState({
            loading: false,
            status,
            ok,
            loaded: true,
            errorCode,
            hasError,
            data,
        });
    }

    private _createState(): ResourceState<T> {
        return {
            ok: false,
            loading: false,
            status: 0,
            loaded: false,
            errorCode: "",
            hasError: false,
            data: null,
        };
    }

    private _updateState(newState: ResourceStateShapePatch<ResourceData<T>>): void {
        this._state = {
            ...this._state,
            ...newState,
        };

        this._stateBS.next(this._state);
    }
}
