import { Component, Input } from "@angular/core";

import { ChapterAverage, DEFAULT_DECIMALS } from "src/app/shared/entities";

import { ChapterAverageTablePivotDefition } from "./chapter-average-table.pivot";

@Component({
    selector: "app-chapter-average-table",
    templateUrl: "./chapter-average-table.component.html",
    styleUrls: ["./chapter-average-table.component.scss"],
})
export class ChapterAverageTableComponent {
    @Input() data: ChapterAverage | null = null;

    readonly defaultDecimals = DEFAULT_DECIMALS;

    constructor(public _pivotDef: ChapterAverageTablePivotDefition) {}
}
