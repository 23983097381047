import { AuthorizationExtendedPermission, AuthRegistryInfo } from "@auth";
import { parseProductCode } from "./product-code";

export const DICA_REGISTRIES = [
    "DACI",
    "DASA",
    "DATO",
    "DBIR",
    "DCRA",
    "DGEA-DRCE",
    "DGOA",
    "DHBA",
    "DHFA",
    "DHNA",
    "DLCA-L",
    "DLCA-R",
    "DLCA-S",
    "DMTR",
    "DPARD",
    "DPCA",
    "DRAIM",
    "DSAA",
    "DUCA",
    "EPSA",
    "NBCA",
    "SONCOS",
];

export function getUniqRegistriesFromPermissions(
    permissions: AuthorizationExtendedPermission[],
): AuthRegistryInfo[] {
    return permissions.reduce((resultRegistries, permission) => {
        const existingRegistry = resultRegistries.find(
            registry => registry.product === permission.product,
        );
        if (existingRegistry == null) {
            const registry = parseProductCode(permission.product);
            if (registry != null) {
                resultRegistries.push({
                    product: permission.product,
                    ...registry,
                    permissions: [permission],
                });
            }
        } else {
            existingRegistry.permissions.push(permission);
        }
        return resultRegistries;
    }, <AuthRegistryInfo[]>[]);
}
