import { Inject, Injectable, inject } from "@angular/core";
import { LG_USER_INFO } from "@logex/framework/lg-application";
import { shareReplay } from "rxjs";
import { AuthApiService, AuthorizationProfile } from "@auth";
import { User } from "../entities/user";

@Injectable()
export class UserProfileService {
    private _authApiService = inject(AuthApiService);

    readonly userProfile$ = this._authApiService.getProfile().pipe(shareReplay(1));

    constructor(@Inject(LG_USER_INFO) public _userInfo: User) {}

    init(): void {
        this.userProfile$.subscribe((userProfile: AuthorizationProfile) => {
            this._userInfo.name = `${userProfile.firstName} ${userProfile.lastName}`;
            this._userInfo.email = userProfile.email;
            this._userInfo.impersonator = userProfile.impersonation?.originalUserEmail;
            this._userInfo.impersonatorName = userProfile.impersonation?.originalUserName;
        });
    }
}
