export function createDefaultUser(): User {
    return new User();
}

export class User {
    id = 0;
    name = "";
    roles: _.Dictionary<boolean> = {};
    title = "";
    userid = "";
    ziekenhuiscode = 0;
    ziekenhuisnaam = "";
    impersonator = "";
    impersonatorName = "";
    email = "";

    hasPermission(role: string): boolean {
        if (role === "switch_language") {
            // hardcoded to avoid language switch
            return true;
        }

        return !!this.roles[role];
    }
}
