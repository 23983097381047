import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BenchmarkView } from "../chapter-percentage-table-benchmark";
import { DEFAULT_DECIMALS } from "src/app/shared/entities";

@Component({
    selector: "app-percentage-table-barchart",
    templateUrl: "./barchart.component.html",
    styleUrls: ["./barchart.component.scss"],
})
export class PercentageTableBarchartComponent implements OnInit {
    @Input() label = "";
    @Input() practitioner = 0;
    @Input() benchmark = 0;
    @Input() benchmarkView: BenchmarkView | "" = "";

    @ViewChild("tooltipContent") tooltipContent: TemplateRef<unknown> | null = null;

    _hoveredChart: "practitioner" | "benchmark" | "" = "";

    readonly defaultDecimals = DEFAULT_DECIMALS;

    ngOnInit(): void {
        this._checkBenchmarkView();
    }

    _setHoveredChart(chartType: "practitioner" | "benchmark"): void {
        this._hoveredChart = chartType;
    }

    private _checkBenchmarkView(): void {
        if (!this.benchmarkView) {
            throw new Error(
                "@Input() benchmarkView is required and can not be empty string, null or undefined value.",
            );
        }
    }
}
