import { Dictionary } from "lodash";
import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";

@Injectable()
export class LocalizationSettings implements LgLocalizationSettings {
    bootstrapDone = Promise.resolve();
    languages: _.Dictionary<any[]> = {};
    private _preferredLanguage;

    constructor(@Inject(LOCALE_ID) private _locale: string) {
        /* let preferredLanguage = localStorage.getItem("preferredLanguage");

        if (!preferredLanguage) {
            preferredLanguage = this._locale;
        }

        this._preferredLanguage = preferredLanguage; */
        this._preferredLanguage = this._locale;
    }

    get fallbackLanguage(): string {
        return "nl-NL";
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        // return ["en-GB", "nl-NL"];
        return ["nl-NL"];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    addStrings(lang: string, strings: Dictionary<any>): void {
        if (!this.languages[lang]) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;
    }

    switchCurrentLanguage(lang: string): void {
        localStorage.setItem("preferredLanguage", lang);
        location.reload();
    }
}
