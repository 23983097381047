import { AuthorizationOrganization, AuthorizationExtendedPermission } from "@auth";

export function filterProductOrganizationsByAuthPerms(
    product: string,
    organizations: AuthorizationOrganization[],
    perms: AuthorizationExtendedPermission[],
): AuthorizationOrganization[] {
    const cicCodes = perms.filter(perm => perm.product === product).map(perm => perm.cicCode);
    return organizations.filter(organization => cicCodes.includes(organization.cicCode));
}
