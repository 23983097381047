import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, UrlSegment, Router } from "@angular/router";

import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard  {
    constructor(private _authService: AuthService, private _router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this._canAccessRegistry("GLI"); // FIXME only for GLI now , it will be generic in future
    }

    canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (route.path) {
            return this._canAccessRegistry("GLI"); // FIXME only for GLI now , it will be generic in future
        } else {
            return of(false);
        }
    }

    private _canAccessRegistry(id: string): Observable<boolean> {
        return this._authService.grantedRegistries$.pipe(
            map(registryIds => registryIds.find(registryId => registryId === id) != null),
            tap(hasAccess => !hasAccess && this._router.navigate(["access-denied"])),
        );
    }
}
