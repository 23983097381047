import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";

import { combineLatest, Observable } from "rxjs";
import { switchMap, take } from "rxjs/operators";

import { LgConsole } from "@logex/framework/core";
import {
    BrowserCacheProvider,
    IHttpRequestOptions,
    ServerGatewayBase,
} from "@logex/framework/lg-application";

import { ConfigurationService } from "@sharedservices";

import { UserService } from "./user.service";

export interface IUrlParams {
    [key: string]: string;
}

@Injectable({
    providedIn: "root",
})
export class ApiService extends ServerGatewayBase {
    constructor(
        _httpClient: HttpClient,
        _cacheProvider: BrowserCacheProvider,
        _lgConsole: LgConsole,
        protected _configuration: ConfigurationService,
        private _userService: UserService,
    ) {
        super();
        this.setBaseUrl(this._configuration.getConfig().registry.apiUrl);
    }

    getResourceData<T>(
        url: string,
        options: IHttpRequestOptions,
        urlParams: IUrlParams = {},
    ): Observable<T | HttpResponse<T>> {
        return combineLatest([this._userService.agbCode$, this._userService.organizationId$]).pipe(
            take(1),
            switchMap(([agbcode, organizationId]) => {
                let params: HttpParams;

                if (!options.params || !(options.params instanceof HttpParams)) {
                    params = new HttpParams({
                        fromObject: options.params ?? {},
                    });
                } else {
                    params = options.params;
                }

                if (agbcode) {
                    urlParams.agbcode = agbcode;
                }

                if (organizationId) {
                    urlParams.provider = String(organizationId);
                }

                options.params = params;

                let fullUrl = url;
                if (urlParams) {
                    fullUrl += "?";
                    Object.keys(urlParams).forEach((key, i) => {
                        if (i > 0) fullUrl += "&";
                        fullUrl += `${key}=${urlParams[key]}`;
                    });
                }

                return this._get<T>(fullUrl, options);
            }),
        );
    }
}
