// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chapter-detail {
  padding: 12px 6px 0;
}

.ghost-ui {
  border-radius: 4px;
  border: 1px solid #eee;
}
.ghost-ui__header {
  height: 35px;
  background: #f3f3f3;
  border-radius: 4px 4px 0 0;
}
.ghost-ui__loader {
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/authorized/components/chapter-detail/chapter-detail.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,sBAAA;AACJ;AACI;EACI,YAAA;EACA,mBAAA;EACA,0BAAA;AACR;AAEI;EACI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR","sourcesContent":[".chapter-detail {\n    padding: 12px 6px 0;\n}\n\n.ghost-ui {\n    border-radius: 4px;\n    border: 1px solid #eee;\n\n    &__header {\n        height: 35px;\n        background: #f3f3f3;\n        border-radius: 4px 4px 0 0;\n    }\n\n    &__loader {\n        height: 125px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
