import { Component, Input } from "@angular/core";

import { ChapterCount } from "src/app/shared/entities";
import { ChapterCountTablePivotDefition } from "./chapter-count-table.pivot";

@Component({
    selector: "app-chapter-count-table",
    templateUrl: "./chapter-count-table.component.html",
    styleUrls: ["./chapter-count-table.component.scss"],
})
export class ChapterCountTableComponent {
    @Input() data: ChapterCount | null = null;

    constructor(public _pivotDef: ChapterCountTablePivotDefition) {}
}
