import { Component, Input } from "@angular/core";
import { ChapterGroup } from "src/app/shared/entities";

@Component({
    selector: "app-chapter-group",
    templateUrl: "./chapter-group.component.html",
    styleUrls: ["./chapter-group.component.scss"],
})
export class ChapterGroupComponent {
    @Input() exporting = false;
    @Input() data: ChapterGroup | null = null;
}
